import { Box, Flex } from 'grid-styled'
import * as React from 'react'
import Layout from '../components/Layout'
import { Helmet } from 'react-helmet'

import CTA from '../components/CTA'
import Header from '../components/Header'
import { FAQContainer, FAQs } from '../components/FAQs'
import { AboutContact } from '.'

export default () => (
  <Layout>
    <Helmet>
      <title>Careers - Just Patios</title>
      <meta
        name="description"
        content="Learn about our exciting career opportunities for Builders, Sales Reps, Managers, and Customer Service!"
      />
    </Helmet>
    <Header>
      <h1>
        Career <i>Opportunities</i>
      </h1>
    </Header>
    <FAQContainer>
      <FAQs>
        <h4 style={{ color: '#F69F1A' }}>Installer / Builder Position</h4>
        <div>
          <p>
            Just Patios has been in the Patio industry since 2002 and we take
            great pride in customer service, customer satisfaction, quality
            workmanship, and all employees /contractors. Just Patios enjoys
            turning customers dreams into reality when it comes to their outdoor
            living areas.
          </p>

          <p>
            Majority of our Manufacturers back their products with 15 year
            warranties which speaks for itself regarding quality and durability.
            For Just Patios to continue this high level of service and
            workmanship we are seeking highly skilled and licensed installers /
            builders to join our team
          </p>

          <p>
            To apply for this position as a Patio Installer/ Builder please read
            below.
          </p>

          <p>Brisbane North and South Region. Travel required. </p>

          <p>
            <strong>
              Top pay rates that are extremely competitive in the industry and
              inviting.
            </strong>{' '}
            Ongoing work that is thoroughly organised by a company that really
            cares and understands the industry.
          </p>

          <ul>
            <li>
              Current Qld BSA license (relevant to the industry a huge plus).
            </li>
            <li>Current ABN, Blue Card and Insurances.</li>
            <li>Registered PTY LTD company (advantage)</li>
            <li>Experience in the Patio Industry.</li>
            <li>Own Reliable Vehicle.</li>
            <li>Own Tools / Ladders.</li>
            <li>Take Pride and Satisfaction in your work.</li>
            <li>
              Professional / Friendly Attitude towards Customers and Staff
            </li>
            <li>Good Problem solving skills.</li>
            <li>Reliable and Punctual</li>
            <li>Enjoy Challenges and are Self Driven</li>
            <li>Good Communication Skills</li>
            <li>Eye for detail.</li>
          </ul>

          <p>
            If you fit the above description and wish to become one of Just
            Patios successful building crews please send resume to
            sales@justpatios.com.au or contact our office. 1300 284 531
          </p>
        </div>
      </FAQs>
    </FAQContainer>

    <AboutContact />
  </Layout>
)
